@media (max-width: 768px) {
    .custom-card {
      width: 100%;
    }
  }
  
  @media (max-width: 400px){
    .row1{
        margin-right: -28px;
    }
  }

  @media(min-width:401px) and (max-width: 700px){
    .row1{
        margin-right: -28px;
    }
  }


  .carousel-indicators {
    bottom: -30px; /* Adjust as needed to position below the card */
  }

  