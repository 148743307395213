.custom-card {
    background-color: rgba(46, 46, 46, 0.5);
    backdrop-filter: blur(10px); 
    color: white; 
    padding: 20px;
    max-width: 1000px; /* Changed width to max-width */
    
  }
  
  .custom-card li {
    font-size: 18px;
    text-align: justify;
    list-style: none; /* Remove default bullets */
    margin: 0; /* Remove default margin */
  }
  
  .yellow-bullet::before {
    content: '●'; /* Yellow bullet character */
    color: #F1B61E; /* Yellow color for bullet */
    font-size: 24px; /* Adjust bullet size */
    margin-right: 10px; /* Add space between bullet and text */
  }
  
  .custom-button {
    background-color: rgba(46, 46, 46, 0.5); 
    border-color: #1e99f1;
    border-width: 3px;
    border-radius: 50px;
    font-size: 18px;
    font-weight: bolder;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin-top: 10px;
    transition-duration: 0.4s;
    cursor: pointer;
  }
  
  .custom-button:hover {
    font-weight: bolder;
    background-color: #1e99f1;
    color: black;
    border: 3px solid #1e99f1;
  }
  
  /* Media query for responsiveness */
  @media only screen and (max-width: 768px) {
    .custom-card {
      padding: 10px;
      width: auto;
      max-width: 90%; /* Adjust card width */
    }
  }
  